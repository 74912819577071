<template>
  <v-content>
    <v-container>
      <p class="headline">All Customers</p>
      <v-row align="center">
        <v-col
          cols="12"
          sm="12"
          md="6"
          xl="4"
          lg="4"
          pa-2
          v-for="(item, i) in allCustomers"
          :key="i"
        >
          <v-card class="mt-2 mb-2">
            <v-card-text>
              <div class="float-right">
                <v-btn small color="primary" @click="showDetails(item)"
                  >Update Grants</v-btn
                >
              </div>
              <span class="subtitle-2">{{ item.name }}</span>
              <br />
              <span>{{ item.email }}</span>
              <br />
              <span class="body-2">@{{ item.username }}</span>
              <br />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      
      <div class="text-center" v-if="allCustomers.length == 0">
        <p class="headline">No Customer is present!</p>
      </div>

      <v-dialog v-model="showCustomer" persistent max-width="700">
        <v-card>
          <div class="d-flex align-center pt-2 pl-2">
            <v-btn icon color="black" class="mr-3" @click="goBack()">
              <v-icon>fas fa-arrow-left</v-icon>
            </v-btn>
            <div class="headline">Customer Details</div>
          </div>
          <v-card-text class="mt-4">
            <v-row>
              <v-col cols="10" sm="8" md="8" lg="8" xl="8">
                <span class="title">{{ customerData.name }}</span>
                <br />
                <span class="subtitle-1">{{ customerData.email }}</span>
              </v-col>
            </v-row>
            <div class="title pb-1 mt-2">Products/Features Selected</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  class="mt-0 mb-0"
                  v-model="customerData.isVideoConference"
                  label="Enable Video Conference?"
                ></v-checkbox>
                <v-checkbox
                  class="mt-0 mb-0"
                  v-model="customerData.disableRightClick"
                  label="Disable right click?"
                ></v-checkbox>
                <v-checkbox
                  class="mt-0 mb-0"
                  v-model="customerData.otpSms"
                  label="Enable 2FA on SMS?"
                ></v-checkbox>
                <v-checkbox
                  class="mt-0 mb-0"
                  v-model="customerData.firstTimeChangePassword"
                  label="First time password change needed?"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  class="mt-0 mb-0"
                  v-model="customerData.isVideoKYC"
                  label="Enable Video KYC?"
                ></v-checkbox>
                <v-checkbox
                  class="mt-0 mb-0"
                  v-model="customerData.useStunTurn"
                  label="Enable stun turn?"
                ></v-checkbox>
                <v-checkbox
                  class="mt-0 mb-0"
                  v-model="customerData.otpEmail"
                  label="Enable 2FA on Email?"
                ></v-checkbox>
                <v-checkbox
                  class="mt-0 mb-0"
                  v-model="customerData.maxRecordRequired"
                  label="Do you want a max limit for recorded video in video conf?"
                ></v-checkbox>
                <v-text-field
                  v-if="customerData.maxRecordRequired"
                  label="Maximum recording time in minutes (for video conf)"
                  v-model="customerData.maxRecordingTime"
                  :rules="rules.timeCheck"
                  ref="variable_maxRecTime"
                ></v-text-field>
              </v-col>
            </v-row>

            <div class="text-right">
              <v-btn color="primary" @click="updateCustomer()">Update</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      

    </v-container>
  </v-content>
</template>

<script>
import { end_points } from "./../../config";
import enLib from "crypto-js/aes";

export default {
  data: () => ({
    allCustomers: [],
    customerData: {
      isVideoConference: false,
      isVideoKYC: false,
      isScreenshare: false,
      isRecording: false,
      otpSms: false,
      otpEmail: false,
      useStunTurn: false,
      firstTimeChangePassword: false,
      disableRightClick: false,
      maxRecordRequired: false,
      maxRecordingTime: "",
      config: {},
    },
    showCustomer: false,
    rules: {
      timeCheck : [
        (value) =>
          !!value ||
          "Cannot be left empty!",
        (value) => 
         !/\D/.test(value) ||
          "Can take only numeric values",
        (value) => 
          !(parseInt(value) < 1 )||
          "Cannot be less than a minute"
      ],
    }
  }),
  methods: {
    showDetails(item) {
      this.showCustomer = true;
      Object.assign(this.customerData, item);
      this.customerData.isVideoKYC = item.config && (item.config.isVideoKYC != undefined) ? item.config.isVideoKYC : (item.isVideoKYC || false);
      this.customerData.isVideoConference = item.config && (item.config.isVideoConference != undefined) ? item.config.isVideoConference : (item.isVideoConference || false);
      this.customerData.otpSms = item.config && (item.config.otpSms != undefined) ? item.config.otpSms : (item.otpSms || false);
      this.customerData.otpEmail = item.config && (item.config.otpEmail != undefined) ? item.config.otpEmail : (item.otpEmail || false);
      this.customerData.disableRightClick = item.config && (item.config.disableRightClick != undefined) ? item.config.disableRightClick : (item.disableRightClick || false);
      this.customerData.useStunTurn = item.config && (item.config.useStunTurn != undefined) ? item.config.useStunTurn : (item.useStunTurn || false);
      this.customerData.firstTimeChangePassword = item.config && (item.config.firstTimeChangePassword != undefined) ? item.config.firstTimeChangePassword : (item.firstTimeChangePassword || false);
      this.customerData.maxRecordRequired = item.config && (item.config.maxRecordRequired != undefined) ? item.config.maxRecordRequired : (item.maxRecordRequired || false);
      this.customerData.maxRecordingTime = item.config && (item.config.maxRecordingTime != undefined) ? item.config.maxRecordingTime : (item.maxRecordingTime || '');
    },
    updateCustomer() {
      //Check if empty
      if(this.customerData && this.customerData.config == undefined){
        this.customerData['config'] = this.customerData.config || {};
      }

      if(this.customerData && this.customerData.config && this.customerData.config.introConf == undefined){
        this.customerData.config['introConf'] = this.customerData.config.introConf || {};
      }
      
      if(this.customerData && this.customerData.config && this.customerData.config.conference == undefined){
        this.customerData.config['conference'] = this.customerData.config.conference || {};
      }
      // this.customerData.config.introConf =
      //   this.customerData.config.introConf || {};
      // this.customerData.config.conference =
      //   this.customerData.config.conference || {};
      //Checking necessary steps
      if (!this.customerData.isVideoConference) {
        this.customerData.config.introConf.isVideoConference = false;
        this.customerData.config.conference.isRecording = false;
        this.customerData.config.conference.isScreenshare = false;
      }
      if (!this.customerData.isVideoKYC) {
        this.customerData.config.introConf.isVideoKYC = false;
        this.customerData.config.introConf.isScreenshare = false;
        this.customerData.config.introConf.isRecording = false;
      }
      this.customerData.config.introConf.useStunTurn = this.customerData.useStunTurn;
      this.customerData.config.introConf.firstTimeChangePassword = this.customerData.firstTimeChangePassword;
      this.customerData.config.introConf.disableRightClick = this.customerData.disableRightClick;
      this.customerData.config.introConf.otpSms = this.customerData.otpSms;
      this.customerData.config.introConf.otpEmail = this.customerData.otpEmail;
      //
      this.customerData.config.introConf.isVideoKYC = this.customerData.isVideoKYC;
      this.customerData.config.introConf.isVideoConference = this.customerData.isVideoConference;
      this.customerData.config.introConf.maxRecordRequired = this.customerData.maxRecordRequired;
      this.customerData.config.introConf.maxRecordingTime = this.customerData.maxRecordingTime;
      this.$nextTick(() => {
        console.log(this.$refs)
        if (this.customerData.maxRecordRequired && !this.$refs.variable_maxRecTime.valid) {
          eventBus.$emit("vueSnack", "Invalid input in fields!");
          this.$refs.variable_maxRecTime.focus();
          this.$refs.variable_maxRecTime.blur();
        } else {
          
          axiosInstance
            .patch(
              end_points.update_customer_grants(this.customerData.id),
              { 
                isVideoConference: this.customerData.isVideoConference,
                isVideoKYC: this.customerData.isVideoKYC,
                isScreenshare: this.customerData.isScreenshare,
                isRecording: this.customerData.isRecording,
                otpSms: this.customerData.otpSms,
                otpEmail: this.customerData.otpEmail,
                config: this.customerData.config,
                useStunTurn: this.customerData.useStunTurn,
                firstTimeChangePassword: this.customerData.firstTimeChangePassword,
                disableRightClick: this.customerData.disableRightClick,
                maxRecordRequired: this.customerData.maxRecordRequired,
                maxRecordingTime: this.customerData.maxRecordingTime
               },
              {
                headers: {
                  sentfrom: "mahakal",
                },
              }
            )
            .then(async (res) => {
              if (res.data) {
                this.goBack();
                await this.getAllCustomers();
                eventBus.$emit("vueSnack", "Successfully Updated grants!");
              } else {
                eventBus.$emit("vueSnack", "Something went wrong!");
              }
            })
            .catch((err) => {
              console.log(err);
              eventBus.$emit("vueSnack", "Something went wrong!");
            });
        }

      })
    },
    goBack() {
      this.showCustomer = false;
      this.customerData = {
        isVideoConference: false,
        isVideoKYC: false,
        isScreenshare: false,
        isRecording: false,
        otpSms: false,
        otpEmail: false,
        maxRecordRequired: false,
        maxRecordingTime: "",
      };
    },
    async getAllCustomers() {
      this.allCustomers = (
        await axiosInstance.get(end_points.get_all_customers)
      ).data;
    },
  },
  created() {
    this.getAllCustomers();
  },
};
</script>


<style scoped>
.v-pagination {
  width: auto !important;
}
</style>
